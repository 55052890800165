import { useState, useEffect } from 'react';

export default function useStaffFilter(searchValue, nodes) {
    const [filteredNodes, setFilteredNodes] = useState(nodes);

    useEffect(() => {
        const nodesFilter = nodes?.filter(filteredNode => {
            const { staffFields, title } = filteredNode;
            const { location, role } = staffFields;
            const staffArray = title + location + role;

            return staffArray.toLowerCase().includes(searchValue.toLowerCase());
        });
        setFilteredNodes(nodesFilter);
    }, [searchValue, nodes]);

    return filteredNodes;
}
