import { useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import useStaffFilter from '@/hooks/useStaffFilter';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
const PageHeader = dynamic(() => import('@/components/PageHeader'));
const Header = dynamic(() => import('@/components/Header'));
const Container = dynamic(() => import('@/components/Container'));
const SearchFilterBar = dynamic(() => import('@/components/SearchFilterBar'));
const StaffCard = dynamic(() => import('@/components/StaffComponents/StaffCard'));
const ContactFormModal = dynamic(() => import('@/components/StaffComponents/ContactFormModal'));
const Footer = dynamic(() => import('@/components/Footer/Footer'));
const ButtonLauncherScript = dynamic(() => import('@/components/DonationScripts/ButtonLauncherScript'));

export default function BranchLocations({ layoutData, pageHeaderSettings }) {
    const { title: branchName, branchLocations } = layoutData;
    const { address, email, events, facebook, allocationId, instagram, twitter, staff, phone, website, youtube } = branchLocations;
    const [searchValue, setSearchValue] = useState('');
    const filteredStaff = useStaffFilter(searchValue, staff);
    const branch = branchName === 'Online Chat' ? branchName : `${branchName} Branch`;

    return (
        <>
            <NextSeo title={`${branch} - Jews for Jesus`} description={branch} canonical={'/find-locations'} type="Page" />
            <Header />
            <PageHeader
                titleContainerBackgroundColor={pageHeaderSettings?.titleContainerBackgroundColor || `var(--color-secondary);`}
                type="branch"
                showTitle={true}
                {...pageHeaderSettings}>
                <h1 className="mb-4 text-white">{branch} Staff</h1>
                <div className="staff-header-link-container inline">
                    <Link className="mr-12 text-white" href="/find-locations" passHref>
                        Go to Locations
                    </Link>
                    <Link className="text-white" href="/staff" passHref>
                        Go to staff list
                    </Link>
                </div>
            </PageHeader>
            <Container width="normal" className="my-10">
                <div className="text-center">
                    <p dangerouslySetInnerHTML={{ __html: address }}></p>
                    {phone && (
                        <a href={`tel: ${phone}`} className="mb-2 block">
                            {phone}
                        </a>
                    )}
                    <p className="address-links content-container mt-4">
                        {email && <ContactFormModal title={branchName} />}
                        {website && (
                            <Link hrefLang="en" target="_blank" href={website}>
                                Branch Website
                            </Link>
                        )}{' '}
                        {facebook && (
                            <Link hrefLang="en" target="_blank" href={facebook}>
                                Facebook
                            </Link>
                        )}{' '}
                        {twitter && (
                            <Link hrefLang="en" target="_blank" href={twitter}>
                                Twitter
                            </Link>
                        )}
                        {instagram && (
                            <Link hrefLang="en" target="_blank" href={instagram}>
                                Instagram
                            </Link>
                        )}
                        {youtube && (
                            <Link hrefLang="en" target="_blank" href={youtube}>
                                YouTube
                            </Link>
                        )}
                        {events && (
                            <Link hrefLang="en" target="_blank" href={events}>
                                Events
                            </Link>
                        )}
                        {allocationId && (
                            <>
                                {/* <a hrefLang="en" onClick={() => setShowModal(true)} onKeyDown={() => setShowModal(true)} role="presentation">
                                    Donate
                                </a> */}
                                {/* <Modal open={showModal} onClose={() => setShowModal(false)}>
                                    <h3 className="my-2 text-center">Donate to {branchName}</h3>
                                    <IDonateForm designationId={branchDesId} />
                                </Modal> */}
                                <ButtonLauncherScript formId="38638" />
                                <a aria-label="Donate" type="button" data-allocation={allocationId} data-formid="38638">
                                    Donate
                                </a>
                            </>
                        )}
                    </p>
                </div>
                <div className="mt-8">
                    <SearchFilterBar value={searchValue} onChangeHandler={e => setSearchValue(e.target.value)} />
                </div>
                {filteredStaff?.map((staffMember, index) => {
                    const { content, featuredImage, title: staffName, uri, staffFields } = staffMember;
                    const { allocationId, idonateDesignationId: staffDesId, role } = staffFields;

                    return (
                        <div key={index} className="staff-container mx-auto mb-16 px-3 py-7 bsm:shadow-staff md:w-[90%] md:p-10 lg:flex lg:flex-wrap">
                            <StaffCard
                                branchPage
                                featuredImage={featuredImage}
                                title={staffName}
                                content={content}
                                role={role}
                                uri={uri}
                                allocationId={allocationId}
                                desId={staffDesId}
                            />
                        </div>
                    );
                })}
                <div className="w-full text-center">
                    <Link className="font-bold text-lightBlue" href="/find-locations">
                        Go Back
                    </Link>
                </div>
            </Container>
            <style>{`
                .address-links a {
                    display: inline-block;
                    margin: 6px 10px;
                }
            `}</style>
            <Footer />
        </>
    );
}
